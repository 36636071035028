var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationProvider',{attrs:{"rules":_vm.f.rules,"name":_vm.f.key,"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
var changed = ref.changed;
return [_c('VSelect',_vm._g(_vm._b({class:['form__field__combo__chip', { disabled: _vm.isDisabled }],attrs:{"items":_vm.f.items,"success":valid && changed,"error-messages":errors,"hide-details":!errors || !errors.length,"multiple":"","small-chips":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('VChip',{attrs:{"label":"","small":""}},[_vm._v(" "+_vm._s(_vm.f.items.indexOf(item) > -1 ? _vm.$t(("commons.professions." + item)) : item)+" ")])]}},{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var parent = ref.parent;
var selected = ref.selected;
var index = ref.index;
return [_c('VChip',_vm._b({class:{ 'pr-1': !_vm.isDisabled },attrs:{"color":((_vm.colors[index]) + " lighten-3"),"input-value":selected,"label":"","small":"","disabled":_vm.isDisabled}},'VChip',attrs,false),[_c('span',[_vm._v(" "+_vm._s(_vm.f.items.indexOf(item) > -1 ? _vm.$t(("commons.professions." + item)) : item)+" ")]),(!_vm.isDisabled)?_c('div',{staticClass:"pointer d-flex justify-center align-center",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return parent.selectItem(item)}}},[_c('GlobalIcon',{staticClass:"ml-2",attrs:{"library":"light","icon":"times-circle","color":"white","size":"16"}})],1):_vm._e()])]}}],null,true),model:{value:(_vm.f.value),callback:function ($$v) {_vm.$set(_vm.f, "value", $$v)},expression:"f.value"}},'VSelect',Object.assign({}, _vm.f.attributes, _vm.$attrs),false),_vm.$listeners))]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }