




















































// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent } from '@vue/composition-api';
// Import utils ------------------------------------------------------------------------------------
import { useCDN } from '@/utils/cdn.utils';
import { useForm, useFormObserver } from '@/utils/forms.utils';

// Import components -------------------------------------------------------------------------------
import AlertError from '@/components/alerts/AlertError.vue';
// Import type -------------------------------------------------------------------------------------
import type { PropType } from '@vue/composition-api';
import type { FormFieldText } from '@/utils/forms.utils';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'AuthWrapper',
  components: {
    AlertError
  },
  props: {
    fields: {
      type: Object as PropType<Record<string, FormFieldText>>,
      required: true
    },
    titleText: {
      type: String,
      required: true
    },
    subtitleText: {
      type: String,
      required: true
    },
    actionText: {
      type: String,
      required: true
    },
    errors: {
      type: Array,
      required: true
    },
    isLoading: Boolean,
    isDisabled: Boolean,
    isErrored: Boolean
  },
  setup(properties, { slots, emit }) {
    const rObserver = useFormObserver();

    const { cdn } = useCDN();

    // Form manager
    const { submit } = useForm(properties.fields, rObserver, (fields) => {
      emit('submit', fields);
    });

    const hasBefore = computed(() => {
      return Boolean(slots.actions);
    });

    return {
      // Refs
      rObserver,
      // Utils
      cdn,
      submit,
      // FLags
      hasBefore
    };
  }
});
