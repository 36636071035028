













































// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent, toRefs } from '@vue/composition-api';
// Import types ------------------------------------------------------------------------------------
import type { PropType } from '@vue/composition-api';
import type { FormFieldSelectChips } from '@/utils/forms.utils';
import type { Colors } from 'vuetify/lib/util/colors';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'FormFieldSelectChips',
  inheritAttrs: false,
  props: {
    field: {
      type: Object as PropType<FormFieldSelectChips>,
      required: true
    }
  },
  setup(properties, { attrs }) {
    // Use this property to have typing in DOM
    const { field } = toRefs(properties);

    const colors: (keyof Colors)[] = [
      'red',
      'pink',
      'purple',
      'deepPurple',
      'indigo',
      'blue',
      'lightBlue',
      'cyan',
      'teal',
      'green',
      'lightGreen',
      'lime',
      'yellow',
      'amber',
      'orange',
      'deepOrange'
    ];

    const isDisabled = computed(() => {
      return attrs.disabled ?? properties.field.attributes?.isDisabled ?? false;
    });

    return {
      f: field as any as FormFieldSelectChips,
      colors,
      // Flags
      isDisabled
    };
  }
});
