

















// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent, toRefs } from '@vue/composition-api';
import { merge } from 'lodash';
// Import types ------------------------------------------------------------------------------------
import type { PropType } from '@vue/composition-api';
import type { FormField } from '@/utils/forms.utils';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'FormFieldCheckbox',
  inheritAttrs: false,
  props: {
    field: {
      type: Object as PropType<FormField>,
      required: true
    },
    ruleValue: {
      type: String,
      default: undefined
    },
    name: {
      type: String,
      default: undefined
    }
  },
  setup(properties, { slots }) {
    // Use this property to have typing in DOM
    const { field, ruleValue } = toRefs(properties);

    const hasLabelSlot = computed(() => {
      return Boolean(slots.label);
    });

    const rules = computed(() => {
      if (field.value.rules.checkbox) {
        return merge({}, field.value.rules, {
          checkbox: ruleValue.value ?? field.value.rules.checkbox
        });
      } else {
        return field.value.rules;
      }
    });

    const key = computed(() => {
      return properties.name ?? field.value.key;
    });

    return {
      f: field as any as FormField,
      key,
      rules,
      // Flags
      hasLabelSlot
    };
  }
});
