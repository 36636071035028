






// Import vendors ----------------------------------------------------------------------------------
import { defineComponent } from '@vue/composition-api';
import { useService } from '@xstate/vue';
// Import plugins ----------------------------------------------------------------------------------
import { usePodocore } from '@/plugins/podocore';
// Import components -------------------------------------------------------------------------------
import AuthCreateProfile from '@/components/auth/createProfile/AuthCreateProfile.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'PageAuthCreateProfile',
  components: { AuthCreateProfile },
  setup() {
    const doctorService = useService(usePodocore().getModuleService('doctor'));

    return {
      doctorService
    };
  }
});
